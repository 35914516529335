import {
  DatePreset,
  DatePresetLabel,
  HistogramInterval,
  DateRange,
} from 'src/types'

export function getDateIntervalLabel(
  date: Date,
  interval: HistogramInterval | 'custom'
): string {
  switch (interval) {
    case HistogramInterval.Minute:
      return date.toLocaleTimeString(process.env.REACT_APP_LOCALE, {
        timeStyle: 'short',
      })
    case HistogramInterval.Hour:
      return date.toLocaleTimeString(process.env.REACT_APP_LOCALE, {
        timeStyle: 'short',
      })
    case HistogramInterval.Day:
      return date.toLocaleDateString(process.env.REACT_APP_LOCALE, {
        month: 'short',
        day: 'numeric',
      })
    case HistogramInterval.Week:
      return date.toLocaleDateString(process.env.REACT_APP_LOCALE, {
        month: 'short',
        day: 'numeric',
      })
    case HistogramInterval.Month:
      return date.toLocaleDateString(process.env.REACT_APP_LOCALE, {
        month: 'short',
      })
    case HistogramInterval.Quarter:
      return date.toLocaleDateString(process.env.REACT_APP_LOCALE, {
        month: 'short',
      })
    case HistogramInterval.Year:
      return date.toLocaleDateString(process.env.REACT_APP_LOCALE, {
        month: 'short',
      })
    case 'custom':
      return date.toLocaleTimeString(process.env.REACT_APP_LOCALE, {
        // day: 'numeric',
        hour: 'numeric',
      })
  }
}

export function getPresetDateRange(key: DatePreset): DateRange {
  let end = new Date()
  end.setMilliseconds(0)

  let start: Date
  let interval: HistogramInterval = HistogramInterval.Hour

  start = new Date(end)

  switch (key) {
    case DatePreset.today:
      start.setHours(0, 0, 0, 0)
      break
    case DatePreset.thisWeek:
      let daysFromSunday = end.getDay() - 1
      let daysOffset

      // If today is Saturday, start the week on last Sunday.
      if (daysFromSunday === -1) {
        daysOffset = 6
        // If today is Sunday, start the week on last Sunday.
      } else if (daysFromSunday === 0) {
        daysOffset = 7
        // If today is Monday, start the week on last Saturday.
        // If Tuesday -> Friday, start the week on last Sunday.
      } else {
        daysOffset = Math.max(daysFromSunday, 2)
      }

      start.setDate(end.getDate() - daysOffset)
      start.setHours(0, 0, 0, 0)
      interval = HistogramInterval.Day
      break
    case DatePreset.thisMonth:
      start.setDate(1)
      start.setHours(0, 0, 0, 0)
      interval = HistogramInterval.Week
      break
    case DatePreset.thisYear:
      start.setMonth(0, 1)
      start.setHours(0, 0, 0, 0)
      interval = HistogramInterval.Month
      break
    case DatePreset.last24Hours:
      start.setHours(end.getHours() - 24)
      break
    case DatePreset.last3Days:
      start = calculateStartDate(end, 3)
      interval = HistogramInterval.Day
      break
    case DatePreset.last7Days:
      start = calculateStartDate(end, 7)
      interval = HistogramInterval.Day
      break
    case DatePreset.last14Days:
      start = calculateStartDate(end, 14)
      interval = HistogramInterval.Day
      break
    case DatePreset.last30Days:
      start = calculateStartDate(end, 30)
      interval = HistogramInterval.Day
      break
    case DatePreset.last90Days:
      start = calculateStartDate(end, 90)
      interval = HistogramInterval.Week
      break
  }

  // Users should not be able to select a date prior to March 12, 2024.
  // This was the last major change to sentiment scores.
  if (start < new Date('2024-03-12')) {
    start = new Date('2024-03-12')
  }

  return {
    key: key,
    value: DatePreset[key],
    label: DatePresetLabel[key],
    start: start.toISOString(),
    end: end.toISOString(),
    interval: interval,
  }
}

export const getCalendarDateRange = (value: [Date, Date]): DateRange => {
  const start = new Date(value[0].setHours(0, 0, 0, 0))
  console.log('start', start)
  let end = new Date(value[1].setHours(23, 59, 59, 999))
  console.log('end', end)

  const isSameDay = start.getDate() === end.getDate()

  const daysDiff = Math.floor(
    (end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24)
  )

  let interval: HistogramInterval

  if (daysDiff <= 30) {
    interval = HistogramInterval.Day
  } else if (daysDiff > 30 && daysDiff <= 90) {
    interval = HistogramInterval.Week
  } else {
    interval = HistogramInterval.Month
  }

  if (isSameDay) {
    interval = HistogramInterval.Hour
  }

  let dateRangeLabel = ''

  if (isSameDay) {
    dateRangeLabel = `${getDateIntervalLabel(start, HistogramInterval.Day)}`
  } else {
    dateRangeLabel = `${getDateIntervalLabel(
      start,
      interval
    )} - ${getDateIntervalLabel(end, interval)}`
  }

  return {
    key: DatePreset.custom,
    value: DatePreset.custom,
    label: dateRangeLabel,
    start: start.toISOString(),
    end: end.toISOString(),
    interval: interval,
  }
}

export const parseToLocalDateTime = (date: string | Date): string => {
  const cleanedDate = date.toString().replace(/ZZ$/, 'Z')

  return (
    new Date(cleanedDate).toLocaleDateString(process.env.REACT_APP_LOCALE, {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    }) +
    ' ' +
    new Date(cleanedDate).toLocaleTimeString(process.env.REACT_APP_LOCALE, {
      timeStyle: 'short',
    })
  )
}

export const parseToLocalDate = (date: string | Date): string => {
  return new Date(date).toLocaleDateString(process.env.REACT_APP_LOCALE, {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  })
}

export const calculateStartDate = (end: Date, lastXDays: number): Date => {
  let start = new Date(end)

  // Subtract one minus lastXDays from the end date, so that the end date is included.
  start.setDate(end.getDate() - (lastXDays - 1))
  // start.setHours(0, 0, 0, 0)
  start.setMilliseconds(0)

  return start
}
