export enum AbuseType {
  ABLEIST = 'ABLEIST',
  ANTI_CHRISTIAN = 'ANTI_CHRISTIAN',
  ANTI_SEMITIC = 'ANTI_SEMITIC',
  BODY_SHAMING = 'BODY_SHAMING',
  HOMOPHOBIC = 'HOMOPHOBIC',
  ISLAMOPHOBIC = 'ISLAMOPHOBIC',
  INSULT = 'INSULT',
  MICROAGGRESSION = 'MICROAGGRESSION',
  MICROAGGRESSION_GENDER = 'MICROAGGRESSION_GENDER',
  MICROAGGRESSION_RACIAL = 'MICROAGGRESSION_RACIAL',
  NSFW = 'NSFW',
  OFFENSIVE_LANGUAGE = 'OFFENSIVE_LANGUAGE',
  PERSONAL_ATTACK = 'PERSONAL_ATTACK',
  RACIST = 'RACIST',
  SEXIST = 'SEXIST',
  SEXUAL = 'SEXUAL',
  SLANDEROUS = 'SLANDEROUS',
  SPAM = 'SPAM',
  THREAT = 'THREAT',
  TRANSPHOBIC = 'TRANSPHOBIC',
  VIOLENT_LANGUAGE = 'VIOLENT_LANGUAGE',
  XENOPHOBIC = 'XENOPHOBIC',
}

export const AbuseTypeLabels: Record<string, string> = {
  ALL: 'All Abuse Types',
  ABLEIST: 'Ableist',
  ANTI_CHRISTIAN: 'Anti-Christian',
  ANTI_SEMITIC: 'Anti-Semitic',
  BODY_SHAMING: 'Body Shaming',
  HOMOPHOBIC: 'Homophobic',
  ISLAMOPHOBIC: 'Islamophobic',
  INSULT: 'Insult',
  MICROAGGRESSION: 'Microaggression',
  MICROAGGRESSION_GENDER: 'Gender Microaggression',
  MICROAGGRESSION_RACIAL: 'Racial Microaggression',
  NSFW: 'Porn Bot',
  OFFENSIVE_LANGUAGE: 'Offensive',
  PERSONAL_ATTACK: 'Personal Attack',
  RACIST: 'Racism',
  SEXIST: 'Sexist',
  SEXUAL: 'Sexual',
  SLANDEROUS: 'Slanderous',
  SPAM: 'Spam',
  THREAT: 'Threat',
  TRANSPHOBIC: 'Transphobic',
  VIOLENT_LANGUAGE: 'Violent',
  XENOPHOBIC: 'Xenophobic',
}
